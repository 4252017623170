@font-face {
  font-family: "SofiaPro";
  src: url("../fonts/sofia-pro/sofia-pro-regular-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro";
  font-style: italic;
  src: url("../fonts/sofia-pro/sofia-pro-regular-italic-az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro";
  src: url("../fonts/sofia-pro/sofia-pro-regular-az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro";
  font-style: italic;
  src: url("../fonts/sofia-pro/sofia-pro-regular-italic-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-Regular";
  src: url("../fonts/sofia-pro/sofia-pro-regular-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-Regular Italic";
  src: url("../fonts/sofia-pro/sofia-pro-regular-italic-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-Black";
  src: url("../fonts/sofia-pro/sofia-pro-black-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-Black Italic";
  src: url("../fonts/sofia-pro/sofia-pro-black-italic-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-Medium";
  src: url("../fonts/sofia-pro/sofia-pro-medium-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-Medium Italic";
  src: url("../fonts/sofia-pro/sofia-pro-medium-italic-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-Bold";
  src: url("../fonts/sofia-pro/sofia-pro-bold-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-Bold Italic";
  src: url("../fonts/sofia-pro/sofia-pro-bold-italic-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-SemiBold";
  src: url("../fonts/sofia-pro/sofia-pro-semi-bold-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-SemiBold Italic";
  src: url("../fonts/sofia-pro/sofia-pro-semi-bold-italic-az.woff")
    format("woff");
}

@font-face {
  font-family: "SofiaPro-Light";
  src: url("../fonts/sofia-pro/sofia-pro-light-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-Light Italic";
  src: url("../fonts/sofia-pro/sofia-pro-light-italic-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-UltraLight";
  src: url("../fonts/sofia-pro/sofia-pro-ultra-light-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-UltraLight Italic";
  src: url("../fonts/sofia-pro/sofia-pro-ultra-light-italic-az.woff")
    format("woff");
}

@font-face {
  font-family: "SofiaPro-ExtraLight";
  src: url("../fonts/sofia-pro/sofia-pro-extra-light-az.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-ExtraLight Italic";
  src: url("../fonts/sofia-pro/sofia-pro-extra-light-italic-az.woff")
    format("woff");
}

body {
  margin: 0;
  font-family: "SofiaPro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #14133d;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-center {
  text-align: center;
}

.color-white {
  color: white;
}

.color-primary {
  color: #00dde1;
}

.MuiInput-underline:before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid white !important;
}

.font-small {
  font-size: 10px;
}
.ReactVirtualized__List {
  outline: none !important;
}
