.ripple {
    position: absolute;
    border-radius: 50%;
    transition: background 0s;
    background-color: rgba(235, 233, 233, 0.7) !important;
}

.rippleContainer{
    position: relative;
    overflow: hidden;
}
